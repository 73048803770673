import React from 'react';
// import './Destinations.css';  // 假设单独的 CSS 文件用于特定样式

const Destinations = () => {
    return (
        <section id="destinations">
            <h2>热门景点</h2>
            <div className="destination">
                <img src="https://source.unsplash.com/100x100/?landmark" alt="热门景点" />
                <div>
                    <h3>埃菲尔铁塔</h3>
                    <p>体验巴黎的浪漫和美丽。</p>
                </div>
            </div>
            <div className="destination">
                <img src="https://source.unsplash.com/100x100/?beach" alt="沙滩" />
                <div>
                    <h3>马尔代夫</h3>
                    <p>享受阳光和海滩的美好时光。</p>
                </div>
            </div>
        </section>
    );
};

export default Destinations;
