import React from 'react';

function Navbar() {
    return (
        <nav>
            <ul>
                <li><a href="#home">首页</a></li>
                <li><a href="#features">功能介绍</a></li>
                <li><a href="#destinations">热门景点</a></li>
                <li><a href="#food">热门美食</a></li>
                <li><a href="#experiences">新奇玩法</a></li>
                <li><a href="#download">下载</a></li>
                <li><a href="#contact">联系我们</a></li>
            </ul>
        </nav>
    );
}

export default Navbar;
