import React from 'react';

function Banner() {
    return (
        <div className="banner">
            <h1>车旅路书：您的终极旅行伴侣</h1>
            <p>智能路线规划 | 热门景点 | 美食指南 | 新奇玩法</p>
        </div>
    );
}

export default Banner;
