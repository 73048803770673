import React from 'react';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Features from './components/Features';
import Destinations from './components/Destinations';
import Food from './components/Food';
import Experiences from './components/Experiences';
import Footer from './components/Footer';
import Download from './components/Download';
import Contact from './components/Contact';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <Features />
      <Destinations />
      <Food />
      <Experiences />
      <Download />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
