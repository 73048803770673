import React from 'react';
// import './Features.css';  // 假设单独的 CSS 文件用于特定样式

const Features = () => {
    return (
        <section id="features">
            <h2>功能介绍</h2>
            <div className="feature">
                <img src="https://source.unsplash.com/100x100/?map" alt="地图功能" />
                <div>
                    <h3>智能路线规划</h3>
                    <p>使用智能算法为您规划最优路线。</p>
                </div>
            </div>
            <div className="feature">
                <img src="https://source.unsplash.com/100x100/?gps" alt="GPS功能" />
                <div>
                    <h3>实时导航</h3>
                    <p>提供实时导航，确保您不迷路。</p>
                </div>
            </div>
        </section>
    );
};

export default Features;
