import React from 'react';
// import './Food.css';  // 假设单独的 CSS 文件用于特定样式

const Food = () => {
    return (
        <section id="food">
            <h2>热门美食</h2>
            <div className="food">
                <img src="https://source.unsplash.com/100x100/?food" alt="美食" />
                <div>
                    <h3>意大利面</h3>
                    <p>品尝正宗的意大利美食。</p>
                </div>
            </div>
            <div className="food">
                <img src="https://source.unsplash.com/100x100/?sushi" alt="寿司" />
                <div>
                    <h3>日本寿司</h3>
                    <p>体验新鲜的海味。</p>
                </div>
            </div>
        </section>
    );
};

export default Food;
