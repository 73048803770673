import React from 'react';

function Footer() {
    return (
        <footer>
            <p>© 2024 车旅路书. 版权所有. <a href="https://beian.miit.gov.cn/" target="_blank">备案号：京ICP备2024072378号-1</a></p>
        </footer>
    );
}

export default Footer;
