import React from 'react';

function Download() {
    return (
        <section id="download">
            <h2>立即下载车旅路书APP</h2>
            <div className="download-buttons">
                <a href="#ios-download">iOS下载</a>
                <a href="#android-download">Android下载</a>
            </div>
        </section>
    );
}

export default Download;
