import React from 'react';
// import './Experiences.css';  // 假设单独的 CSS 文件用于特定样式

const Experiences = () => {
    return (
        <section id="experiences">
            <h2>新奇玩法</h2>
            <div className="experience">
                <img src="https://source.unsplash.com/100x100/?adventure" alt="冒险" />
                <div>
                    <h3>探险之旅</h3>
                    <p>体验惊险刺激的探险活动。</p>
                </div>
            </div>
            <div className="experience">
                <img src="https://source.unsplash.com/100x100/?culture" alt="文化体验" />
                <div>
                    <h3>文化体验</h3>
                    <p>深入了解当地文化和风俗。</p>
                </div>
            </div>
        </section>
    );
};

export default Experiences;
