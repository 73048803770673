import React from 'react';

function Contact() {
    return (
        <section id="contact">
            <h2>联系我们</h2>
            <p>客服邮箱：<a href="mailto:tcroadbook@163.com">tcroadbook@163.com</a></p>
            <h3>关注我们</h3>
            <p>微信公众号：车旅路书</p>
        </section>
    );
}

export default Contact;
